import { api } from "../../services/api";
import types from "../types";
import { isAuthenticated, authenticate } from "../../services/auth";
import Constants from "../../services/constant";
import { showToast } from "../../utils/showToast";
import { showLoaderAction, hideLoaderAction } from "./loadAction";
var base64 = require("base-64");

export const loginAction = (studentData, history, type) => async (dispatch) => {
    dispatch(showLoaderAction());
    const { user_name, password, rememberMe } = studentData;

    const payload = {
        user_name,
        password,
    };

    const res = await api("post", Constants.END_POINT.LOGIN, payload);
    var encoded = base64.encode(password);
    var role = base64.encode(type);
    if (res.success) {
        if (rememberMe == true) {
            localStorage.setItem("rememberMe", rememberMe);
            localStorage.setItem("userName", rememberMe ? user_name : "");
            localStorage.setItem("Detail", rememberMe ? encoded : "");
            localStorage.setItem("Role", rememberMe ? role : "");
        } else {
            localStorage.removeItem("userName");
            localStorage.removeItem("Detail");
            localStorage.setItem("rememberMe", rememberMe);
            localStorage.setItem("Role", role);
        }

        if (res.data) {
            dispatch({
                type: types.USER_DETAIL,
                payload: res.data,
            });
            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    showToast([res.message], "success");
                    history.push("/student/dashboard");
                }
                if (!isAuthenticated) {
                    history.push("/");
                }
            });
        }
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const ParentloginAction = (parentData, history, type, location) => async (dispatch) => {
    dispatch(showLoaderAction());
    const { email, password, parentrememberMe } = parentData;

    const payload = {
        email,
        password,
    };

    const res = await api("post", Constants.END_POINT.PARENT_LOGIN, payload);

    var encoded = base64.encode(password);
    var role = base64.encode(type);
    if (res.success) {
        if (parentrememberMe == true) {
            localStorage.setItem("parentrememberMe", parentrememberMe);
            localStorage.setItem("parentEmail", parentrememberMe ? email : "");
            localStorage.setItem("parentDetail", parentrememberMe ? encoded : "");
            localStorage.setItem("Role", parentrememberMe ? role : "");
        } else {
            localStorage.removeItem("parentEmail");
            localStorage.removeItem("parentDetail");
            localStorage.setItem("parentrememberMe", parentrememberMe);
            localStorage.setItem("Role", role);
        }

        if (res.data) {
            authenticate(res.data.token, () => {
                dispatch({
                    type: types.USER_DETAIL,
                    payload: res.data,
                });
                if (isAuthenticated()) {
                    showToast([res.message], "success");
                    if (res.data.is_update_password == 0) {
                        history.push("/change-password");
                    } else if (location?.state) {
                        dispatch(getParentProfileApi());
                        history.push(location.state.referrer.pathname + location.state.referrer.search);
                    } else {
                        history.push("/parent/dashboard");
                    }
                }
                if (!isAuthenticated) {
                    history.push("/");
                }
            });
        }
    } else {
        showToast([res.message], "error");
        dispatch(hideLoaderAction());
    }
    dispatch(hideLoaderAction());
};
export const TeacherloginAction = (teacherData, history, type) => async (dispatch) => {
    dispatch(showLoaderAction());
    const { email, password, teacherrememberMe } = teacherData;

    const payload = {
        email,
        password,
    };

    const res = await api("post", Constants.END_POINT.TEACHER_LOGIN, payload);
    var encoded = base64.encode(password);
    var role = base64.encode(type);
    if (res.success) {
        if (teacherrememberMe == true) {
            localStorage.setItem("teacherrememberMe", teacherrememberMe);
            localStorage.setItem("teacherEmail", teacherrememberMe ? email : "");
            localStorage.setItem("teacherDetail", teacherrememberMe ? encoded : "");
            localStorage.setItem("Role", teacherrememberMe ? role : "");
        } else {
            localStorage.removeItem("teacherEmail");
            localStorage.removeItem("teacherDetail");
            localStorage.setItem("teacherrememberMe", teacherrememberMe);
            localStorage.setItem("Role", role);
        }

        if (res.data) {
            dispatch({
                type: types.USER_DETAIL,
                payload: res.data,
            });
            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    showToast([res.message], "success");
                    history.push("/teacher-dashboard");
                }
                if (!isAuthenticated) {
                    history.push("/");
                }
            });
        }
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

export const logoutAction = () => (dispatch) => {
    dispatch({
        type: types.USER_DETAIL,
        payload: {
            token: "",
        },
    });
};
export const sendEmailApi = (studentEmail, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    var encodedEmail = base64.encode(studentEmail);
    const payload = { email: studentEmail };
    const res = await api("post", Constants.END_POINT.SENT_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push(`/student-verify-otp/${encodedEmail}`);
    } else {
        showToast([res.message], "error");
    }
};
export const parentSendEmailApi = (studentEmail, next) => async (dispatch, getState) => {
    dispatch(showLoaderAction());

    const payload = { email: studentEmail };
    const res = await api("post", Constants.END_POINT.PARENT_SENT_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        next();
    } else {
        showToast([res.message], "error");
    }
};
export const teacherSendEmailApi = (studentEmail, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    var encodedEmail = base64.encode(studentEmail);
    const payload = { email: studentEmail };
    const res = await api("post", Constants.END_POINT.TEACHER_SENT_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push(`/teacher-verify-otp/${encodedEmail}`);
    } else {
        showToast([res.message], "error");
    }
};
export const sendOtpApi = (otp, email, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    var encodedEmail = base64.encode(email);

    const payload = { email: email, otp: otp };
    const res = await api("post", Constants.END_POINT.VERIFY_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push(`/student-reset-password/${encodedEmail}`);
    } else {
        showToast([res.message], "error");
    }
};
export const parentSendOtpApi = (otp, email, next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = { email: email, otp: otp };
    const res = await api("post", Constants.END_POINT.PARENT_VERIFY_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        next();
    } else {
        showToast([res.message], "error");
    }
};
export const teacherSendOtpApi = (otp, email, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    var encodedEmail = base64.encode(email);

    const payload = { email: email, otp: otp };
    const res = await api("post", Constants.END_POINT.TEACHER_VERIFY_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push(`/teacher-reset-password/${encodedEmail}`);
    } else {
        showToast([res.message], "error");
    }
};
export const sendResetPasswordApi = (email, password, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = { email: email, new_password: password };
    const res = await api("post", Constants.END_POINT.RESET_PASSWORD, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push("/");
    } else {
        showToast([res.message], "error");
    }
};
export const teacherSendResetPasswordApi = (email, password, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = { email: email, new_password: password };
    const res = await api("post", Constants.END_POINT.TEACHER_RESET_PASSWORD, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push("/");
    } else {
        showToast([res.message], "error");
    }
};
export const parentSendResetPasswordApi = (email, password, next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = { email: email, new_password: password };
    const res = await api("post", Constants.END_POINT.PARENT_RESET_PASSWORD, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        next();
    } else {
        showToast([res.message], "error");
    }
};

export const getStudentProfileApi = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_STUDENT_PROFILE);
    dispatch(hideLoaderAction());

    if (res.success) {
        dispatch({
            type: types.GET_STUDENT_PROFILE,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
};
export const getTeacherProfileApi = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_TEACHER_PROFILE);
    dispatch(hideLoaderAction());

    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_PROFILE,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
};
export const getParentProfileApi = () => async (dispatch, getState) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_PARENT_PROFILE);
    dispatch(hideLoaderAction());
    if (res.success) {
        dispatch({
            type: types.GET_PARENT_PROFILE,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
};

export const changePasswordAction = (data, history) => async (dispatch, getState) => {
    dispatch(showLoaderAction());
    const state = getState();
    const payload = {
        password: data?.password,
        new_password: data?.new_password,
    };
    const res = await api("post", Constants.END_POINT.CHANGE_PASSWORD, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        state.login.getParentProfile.is_update_password = 1;
        history.push("/parent/dashboard");
    } else {
        showToast([res.message], "error");
    }
};
